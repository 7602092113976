<template>
    <div class="modal" v-click-outside="close">
        <div class="content">
            <div class="main">
                <IconCursor/>
                <div class="text">Ваша страна <span>Россия?</span></div>
            </div>
            <div class="actions">
                <ui-button class="btn" label="Нет" color="ghost" @click="close"/>
                <ui-button class="btn" label="Да" color="primary" @click="toDflat"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import IconCursor from '~/assets/svg/Cursor.svg'

const emit = defineEmits(['close'])

function close() {
    emit('close')
}

function toDflat() {
    const a = document.createElement('a')
    a.href = 'https://d-flat.ru/ru'
    a.click()
}
</script>

<style lang="scss" scoped>
.modal{
    z-index: 99999;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 174px;
    padding: 24px 16px 50px;
    background: #FFF;
    box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.16), 0px 4px 4px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    justify-content: center;
    align-items: center;
    .content{
        height: 100%;
        width: 428px;
        display: flex;
        flex-direction: column;
        @media (max-width: 460px){
            width: 100%;
        }
        .main{
        display: flex;
        gap: 16px;
        .text{
            color: var(--white-theme-black-100, #3D424A);
            font-family: Segoe UI;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.48px;
            & > span {
                font-weight: 700;
                line-height: normal;
            }
        }
    }
    .actions{
        margin-top: auto;
        display: flex;
        gap: 16px;
        .btn{
            width: 190px;
        }
    }
    }
}
</style>